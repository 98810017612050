module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grayscale","short_name":"Landing","start_url":"/gatsby-starter-grayscale/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/jukin-media-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"85b49ab09675b9e389dcac4f07588c72"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
